import { cn } from '@/Lib'
import { motion, useSpring, useTransform } from 'framer-motion'
import { FC, ReactNode, useEffect } from 'react'

type Ring = {
  percentage: number
  colour: string
}

type RingsProps = {
  size?: string
  backgroundColour: string
  children: ReactNode
  primary: Ring
}

export const Rings: FC<RingsProps> = ({
  size,
  backgroundColour,
  children,
  primary
}) => {
  // Note: don't change these unless you know how to change the svg radii
  const radius = 30
  const circumference = 2 * Math.PI * radius

  const spring = useSpring(primary.percentage, { duration: 300, bounce: 0 })
  const primaryStrokeDashoffset = useTransform(
    spring,
    (current) => circumference - current * circumference
  )

  useEffect(() => {
    spring.set(primary.percentage)
  }, [spring, primary.percentage])

  return (
    <div className='relative inline-flex min-h-max min-w-max select-none items-center justify-center overflow-hidden rounded-full bg-white'>
      {/*Building a Progress Ring: https://css-tricks.com/building-progress-ring-quickly/*/}
      <svg className={cn('aspect-square -rotate-90', size)} viewBox='0 0 80 80'>
        <circle
          className={cn(
            // recommendedSavings - totalSavings > 0 ? 'text-green-100' :
            backgroundColour
          )}
          strokeWidth='8'
          stroke='currentColor'
          fill='transparent'
          r='30'
          cx='40'
          cy='40'
        />
        <motion.circle
          className={primary.colour}
          strokeDasharray={circumference}
          strokeDashoffset={primaryStrokeDashoffset}
          strokeWidth='8'
          strokeLinecap='round'
          stroke='currentColor'
          fill='transparent'
          r='30'
          cx='40'
          cy='40'
        />
      </svg>
      {children}
    </div>
  )
}
